<script>
import Layout from "../layouts/main.vue";
import PageHeader from "@/components/page-header";
import axios from 'axios';

export default {
    components: {
        Layout,
        PageHeader,
    },
    data(){
        return {
            title: 'Notification',
            activityDatas: [],
            startDate:'',
            endDate:'',
            filter: null,
        }
    },
    methods: {
        getNotifall(){
            let param = `0/0`;
            if (this.startDate && this.endDate) {
                param = `${this.startDate}/${this.endDate}`;
            }
            let id  = JSON.parse(localStorage.getItem("user")).user_id;
            axios.get(`v1/get-notifall/${id}/${param}`)
            .then((res) => {
                this.activityDatas = res.data.notif;
                // if(this.filter) {
                //     let filtered = this.activityDatas.filter((x) => {
                //         return x.title.toUpperCase().indexOf(this.filter.toUpperCase()) > -1
                //     })
                //     this.activityDatas = filtered
                // }
            })
        },
    },
    created () {
        this.getNotifall();
    },
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="row">
            <div class="col-lg-6">
                <div class="card p-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <label class="d-inline-flex align-items-center">
                                Date:
                                <b-form-input class="form-control form-control-sm ml-3" v-model="startDate" type="date" icon="">
                                </b-form-input>
                                <b-form-input class="form-control form-control-sm" v-model="endDate" type="date" icon="">
                                </b-form-input>
                                <b-button variant="info" class="form-control form-control-sm py-1" @click="getNotifall"
                                >Submit</b-button>
                                </label>
                            </div>
                            <!-- <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                        v-model="filter"
                                        @keyup="getNotifall"
                                        type="search"
                                        placeholder="Search..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div> -->
                        </div>
                        <ul class="list-unstyled activity-wid p-3 ml-5">
                            <li class="activity-list" v-for="(data, index) in activityDatas" :key="index">
                            <div class="activity-icon avatar-xs">
                                <span v-if="data.title.includes('Completed')" class="avatar-title bg-soft-success text-success rounded-circle">
                                <i class="ri-bar-chart-fill"></i>
                                </span>
                                <span v-else-if="data.title.includes('Processing')" class="avatar-title bg-soft-warning text-warning rounded-circle">
                                <i class="ri-bar-chart-fill"></i>
                                </span>
                                <span v-else-if="data.title.includes('Rejected') || data.title.includes('Refunded') || data.title.includes('Cancelled')" class="avatar-title bg-soft-danger text-danger rounded-circle">
                                <i class="ri-bar-chart-fill"></i>
                                </span>
                                <span v-else class="avatar-title bg-soft-info text-info rounded-circle">
                                <i class="ri-bar-chart-fill"></i>
                                </span>
                            </div>
                            <div>
                                <div>
                                <h5 class="font-size-13">
                                    {{data.create_at}}
                                    <small class="text-muted">{{data.time}}</small>
                                </h5>
                                </div>

                                <div>
                                <p class="text-muted mb-0">{{data.title}}</p>
                                </div>
                            </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>